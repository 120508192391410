import * as igluData from "@realestate.com.au/iglu-data"
import * as argonautAnalytics from "@rea-argonaut/analytics"

import {
  pageDataOptionsTransformer,
  articleDataTransformer
} from "../transformers"
import { getUtag } from "../utilities"

export default class TealiumService {
  constructor(
    pageData,
    categories,
    searchParams,
    analytics = argonautAnalytics,
    dataGetterGenerator = igluData
  ) {
    this.pageData = pageData
    this.searchParams = searchParams
    this.isDraftForFriends = this.searchParams.get("draftsforfriends")
    const defaultViewData = dataGetterGenerator.getDefaultViewData({
      page: pageDataOptionsTransformer(this.pageData, categories, searchParams)
    })
    this.dataGetters = [
      ...defaultViewData,
      () => articleDataTransformer(this.pageData)
    ]
    this.analytics = analytics
  }

  tealiumLoader = {
    load: () => getUtag(window),
    setUtagOverride: () => {}
  }

  getdataLayer = () => {
    return this.dataGetters.reduce(
      (data, dataGetter) => ({
        ...data,
        ...dataGetter()
      }),
      {}
    )
  }

  trackPageView() {
    if(!this.isDraftForFriends){
      this.analytics.clearView()
      this.analytics.trackView(this.dataGetters)
    }
  }

  trackEvent(event) {
    !this.isDraftForFriends && this.analytics.trackEvent(() => event)
  }
}
