const WARN_MESSAGE =
  "Argonaut analytics function calls should be wrapped in a useEffect hook to prevent them running on the server"

const getUtag = (_window) => {
  if (typeof _window === "undefined") {
    console.warn(WARN_MESSAGE)
    return Promise.resolve({
      view: () => undefined,
      link: () => undefined,
      data: {}
    })
  }

  return new Promise((resolve) =>
    _window["@argonaut/analytics/loadTealium"](() => resolve(_window.utag))
  )
}

export default getUtag
export { WARN_MESSAGE }
